import { useMemo, useState } from 'react'
import { useHandleException, useMediaQueries } from 'hooks'
import { useGetSkillsCvs } from 'requests/hooks/cvs.hook'
import { useSelector } from 'store'
import { RESOLVED } from 'types/status'
import type { UserStateType } from 'store/slices/user.slice'
import type { Cv } from 'requests/models/objects'
import type { StatusType } from 'types/status'

export type UseSkillsReturns = {
    /** Items */
    items?: Cv[]
    /** Status */
    status: StatusType
    /** Results */
    results: string
    /** CategoriesOpen */
    categoriesOpen: boolean[]
    /** SetCategoriesOpen */
    setCategoriesOpen: React.Dispatch<React.SetStateAction<boolean[]>>
    /** Columns */
    columns: {
        /** Name */
        name: string
        /** Elements */
        elements: {
            /** Name */
            name: string
        }[]
    }[]
    /** SelectedNameIndex */
    selectedNameIndex: number
    /** IsMobile */
    isMobile: boolean
    /** Me */
    me: UserStateType['me']
}

/**
 * UseSkillsPage
 */
export default function useSkills(): UseSkillsReturns {
    const { handleException } = useHandleException()
    const [categoriesOpen, setCategoriesOpen] = useState<boolean[]>([])
    const query = useSelector(state => state.common.query)
    const me = useSelector(state => state.user.me)
    const { isMobile } = useMediaQueries()

    const { data: items, status } = useGetSkillsCvs(
        { ...query },
        {
            enabled: query.q !== null,
            onError(err) {
                handleException(err)
            },
            onSuccess(data) {
                setCategoriesOpen(data?.[0]?.scores.filter(score => score.elements.length > 0).map((_, i) => i < 1))
            },
        },
    )

    /** Number of items found */
    const results = useMemo(
        () => (status === RESOLVED ? `${new Intl.NumberFormat('fr-fr').format(items?.length || 0)} résultats` : ''),
        [items?.length, status],
    )

    const columns = useMemo(
        () =>
            items?.[0]?.scores
                .filter(score => score.elements.length > 0)
                .map(score => ({
                    name: score.name,
                    elements: score.elements.map(element => ({
                        name: element.name,
                    })),
                })) ?? [],
        [items],
    )

    const selectedNameIndex = useMemo(() => categoriesOpen?.findIndex(categoryOpen => categoryOpen), [categoriesOpen])

    return {
        items,
        status,
        results,
        categoriesOpen,
        setCategoriesOpen,
        columns,
        selectedNameIndex,
        isMobile,
        me,
    }
}
